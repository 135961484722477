import { Component } from '@angular/core';
import { translations } from '../../translations';

@Component({
  selector: 'regas-idp-activate-account-mail-info',
  templateUrl: './idp-activate-account-mail-info.component.html',
  styleUrls: ['./idp-activate-account-mail-info.component.scss'],
})
export class IdpActivateAccountMailInfoComponent {
  readonly translations = translations;
}
