<regas-not-found-page
  header=""
  content=""
  imageUrl="assets/images/login/page-warning.svg"
>
  <div class="error-container">
    <div class="card">
      <regas-input-error>
        {{
          errorTranslationCode
            ? (errorTranslationCode | translate: translations)
            : 'error retrieving IdP data'
        }}
      </regas-input-error>
    </div>
  </div>
</regas-not-found-page>
