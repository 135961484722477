import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { translations } from '../../translations';
import { SsoErrorCode } from '../../services/sso/sso-error/sso-error.service';

@Component({
  selector: 'regas-idp-error',
  templateUrl: './idp-error.component.html',
  styleUrls: ['./idp-error.component.scss'],
})
export class IdpErrorComponent implements OnInit {
  readonly translations = translations;
  errorTranslationCode: string;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.initErrorTranslationCode();
  }

  private initErrorTranslationCode(): void {
    if (
      SsoErrorCode[
        this.activatedRoute.snapshot.params['errorCode']
      ]?.toString() === SsoErrorCode.OIDC_IP.toString()
    ) {
      this.errorTranslationCode = 'error.ipRestricted';
    } else {
      this.errorTranslationCode = 'registration.error.activationCodeNotFound';
      switch (this.activatedRoute.snapshot.params['errorCode']) {
        case 'ACTIVATION_NOT_FOUND':
          this.errorTranslationCode =
            'registration.error.activationCodeNotFound';
          break;
        case 'ACTIVATION_ERROR':
          this.errorTranslationCode = 'error.activationExpired';
          break;
        default:
          break;
      }
    }
  }
}
