import { Routes } from '@angular/router';
import { AutologoutComponent } from '../logout/autologout.component';
import { ssoRedirectionRouting } from '../sso-redirection-view/sso.routing';
import { clientLoginRouting } from './client-login/client-login.routing';
import { userLoginRouting } from './user-login/user-login.routing';
import { ssoRouting } from './sso/sso.routing';

export const loginRouting: Routes = [
  {
    path: 'logout',
    component: AutologoutComponent,
  },
  ...ssoRouting,
  ...ssoRedirectionRouting,
  ...userLoginRouting,
  ...clientLoginRouting,
];
