import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RegasComponentsProgressIndicatorModule } from '@regas/components';
import {
  CookieService,
  RegasSharedModule,
  SettingsService,
} from '@regas/shared';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { LoginModule } from '../login/login.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { TwoFactorAuthenticationModule } from '../two-factor-authentication/two-factor-authentication.module';
import { ClientRegistrationComponent } from './client-registration/client-registration.component';
import { RegistrationDataComponent } from './registration-data/registration-data.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { EmployeeRegistrationComponent } from './employee-registration/employee-registration.component';
import { IdpActivateAccountMailInfoComponent } from '../components/idp-activate-account-mail-info/idp-activate-account-mail-info.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    LoginModule,
    RegasSharedModule,
    LayoutsModule,
    ComponentsModule,
    PipesModule,
    TwoFactorAuthenticationModule,
    RegasComponentsProgressIndicatorModule,
  ],
  declarations: [
    ClientRegistrationComponent,
    RegistrationDataComponent,
    UserRegistrationComponent,
    EmployeeRegistrationComponent,
    IdpActivateAccountMailInfoComponent,
  ],
  exports: [
    ClientRegistrationComponent,
    UserRegistrationComponent,
    IdpActivateAccountMailInfoComponent,
  ],
  providers: [AuthenticationService, CookieService, SettingsService],
})
export class RegistrationModule {}
