import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { OriginService, Subdomain } from '@regas/shared';
import { Organisation } from '../../models/interface/organisation.interface';
import { REGAS_IAM_ENDPOINT } from '../../models/REGAS_IAM_ENDPOINT';

@Injectable({
  providedIn: 'root',
})
export class OrganisationNameService {
  private readonly organisationName$ = new BehaviorSubject<{
    substitution: string;
    organisation: Organisation;
  } | null>(null);
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  organisationName: string;

  public getOrganisationName(substitution: string): Observable<Organisation> {
    if (this.organisationName$.getValue()?.substitution === substitution) {
      return this.organisationName$.pipe(
        filter(Boolean),
        map(({ organisation }) => organisation),
      );
    }
    return this.httpClient
      .get<Organisation>(
        this.originService.getUrl(
          Subdomain.Api,
          `${REGAS_IAM_ENDPOINT.authentication}/organisation/${substitution}/name`,
        ).href,
      )
      .pipe(
        tap(organisation =>
          this.organisationName$.next({ substitution, organisation }),
        ),
      );
  }
}
