import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { OriginService } from '@regas/shared';
import { Subdomain } from '@regas/bruce';
import { LoaderService } from '../loading/loading.service';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';
import { OrganisationNameService } from '../organisation/organisation-name.service';

export interface IdpStatusResponse {
  status: 'ENABLED' | 'MIGRATING' | 'DISABLED';
  authUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class IdpService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly organisationNameService: OrganisationNameService,
    private readonly originService: OriginService,
    private readonly loaderService: LoaderService,
  ) {}

  checkIdp(
    substitution: string,
    successCallback: (statusResponse: IdpStatusResponse) => Observable<boolean>,
    errorCallback: () => Observable<boolean>,
  ): Observable<boolean> {
    localStorage.setItem(LocalStorageKeys.SUBSTITUTION, substitution);

    this.loaderService.showLoader();
    return this.organisationNameService.getOrganisationName(substitution).pipe(
      switchMap(() =>
        this.checkSubstitution(substitution).pipe(
          switchMap(successCallback),
          catchError(errorCallback),
          finalize(() => this.loaderService.hideLoader()),
        ),
      ),
      catchError(() => {
        this.loaderService.hideLoader();
        return of(true);
      }),
    );
  }

  checkSubstitution(substitution: string): Observable<IdpStatusResponse> {
    return this.httpClient.get<IdpStatusResponse>(
      this.originService.getUrl(Subdomain.Api, `myneva-idp/${substitution}`)
        .href,
    );
  }
}
