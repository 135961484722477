import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  RegasComponentsAlertModule,
  RegasComponentsButtonModule,
  RegasComponentsIconModule,
  RegasComponentsProgressIndicatorModule,
} from '@regas/components';
import { RegasErrorHandler, RegasSharedModule } from '@regas/shared';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { RegistrationModule } from './registration/registration.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ClientPasswordRecoveryModule } from './password-recovery/client-password-recovery.module';
import { SsoRedirectionModule } from './sso-redirection-view/sso-redirection.module';
import { SurveyRequestDialogModule } from './survey-request-dialog/survey-request-dialog.module';
import { LoadingPageModule } from './components/pages/loading-page/loading-page.module';
import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    RegasSharedModule.forRoot(
      !!environment.origins && !!environment.authenticationCookies
        ? {
            ...environment,
            origin: environment.origins,
          }
        : {
            production: environment.production,
          },
    ),
    LoginModule,
    RegistrationModule,
    SsoRedirectionModule,
    ClientPasswordRecoveryModule,
    RegasComponentsIconModule,
    TranslateModule.forRoot(),
    LayoutsModule,
    RegasComponentsButtonModule,
    RegasComponentsProgressIndicatorModule,
    SurveyRequestDialogModule,
    LoadingPageModule,
    ComponentsModule,
    RegasComponentsAlertModule,
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: ErrorHandler, useClass: RegasErrorHandler }],
})
export class AppModule {}
