import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IdpService } from '../../services/idp/idp.service';

@Injectable({
  providedIn: 'root',
})
export class IdpRedirectGuard implements CanActivate {
  constructor(
    private readonly idpService: IdpService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const substitution = route.paramMap.get('substitution') as string;

    return this.idpService.checkIdp(
      substitution,
      ({ status, authUrl }) => {
        if (status === 'ENABLED' && authUrl) {
          this.redirectWithSelectedParams(route, authUrl, ['goto', 'sso_hint']);
          return of(false);
        }
        return of(true);
      },
      () => {
        this.router.navigate(['/idp-error']);
        return of(false);
      },
    );
  }

  private redirectWithSelectedParams(
    route: ActivatedRouteSnapshot,
    authUrl: string,
    paramsToInclude: string[],
  ): void {
    const { queryParams } = route;

    let redirectUrl = authUrl;

    const filteredParams = paramsToInclude
      .filter(param => queryParams[param])
      .map(
        param =>
          `${encodeURIComponent(param)}=${encodeURIComponent(
            queryParams[param],
          )}`,
      )
      .join('&');

    if (filteredParams?.length) {
      redirectUrl = `${authUrl}?${filteredParams}`;
    }

    window.location.href = redirectUrl;
  }
}
