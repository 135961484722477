import { logInVerb } from './log-in-verb';
import { password } from './password';
import { passwordRequired } from './passwordRequired';
import { usernameRequired } from './usernameRequired';
import { username } from './username';
import { code } from './code';

export const de = {
  codeRequired: 'Code eingeben erforderlich',
  fixedLength: `Der ${code.de} muss 6 Ziffern lang sein`,
  company: 'myneva',
  email: 'E-Mail-Adresse',
  emailRequired: 'E-Mail-Adresse is erforderlich',
  enterTheAuthenticatorCode:
    'Geben Sie den von der Authentifizierungs-App bereitgestellten Code ein',
  enterTheSmsCode: 'Geben Sie den gesendeten Code ein',
  error: {
    attemptsLeft1: 'Nur noch 1 Versuch verfügbar.',
    attemptsLeft2: '2 weitere Versuche verfügbar.',
    invalidCredentials: 'E-Mail-Adresse und/oder Passwort ungültig.',
    invalidPasswordChangeData:
      'Die Passwortänderungsanforderung ist abgelaufen oder ungültig.',
    invalidVerificationCode: 'Falscher Bestätigungscode.',
    ipRestricted:
      'Aus dieser IP-Adresse besteht kein Zugriff auf diese Organisation.',
    activationExpired:
      'Der Aktivierungslink ist abgelaufen. Wenn Sie das Problem nicht lösen können, wenden Sie sich bitte an Ihren Anwendungsadministrator.',
    login: 'Einloggen ist zu diesem Zeitpunkt nicht möglich.',
    maximumReached:
      'Maximale Anzahl der erreichten Versuche. Versuche es in ein paar Minuten noch einmal.',
    noCookieSet:
      'Cookies sind deaktiviert. Bitte aktiviere Cookies in deinen Browsereinstellungen, um fortzufahren.',
    passwordSameAsPrevious:
      'Das Passwort ist dasselbe wie das vorherige Passwort.',
    sms: {
      exceeded:
        'Die maximale Anzahl gesendeter SMS ist erreicht. Nach 10 Minuten können Sie die SMS erneut versenden.',
      unexpected:
        'Unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut.',
    },
    sessionExpired: 'Sitzung abgelaufen. Bitte versuche es erneut.',
    unknownError:
      'Unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut.',
    sso: {
      loginFailed:
        'Es ist ein SSO-Fehler aufgetreten.<br>Vorfall id: {{incidentId}}.',
    },
  },
  incorrectCredentials: 'Ungültige E-Mail-Adresse und/oder Passwort',
  invalidEmailFormat: 'Das Format deiner E-Mail-Adresse ist ungültig',
  forgotPassword: 'Passwort vergessen?',
  logIn: logInVerb.de,
  maximumReachedTwoFactor:
    'Maximale Anzahl von Versuchen erreicht. Kontaktieren Sie Ihre Organisation.',
  missingPhoneInfo:
    'Um fortzufahren, geben Sie uns bitte Ihre Telefonnummer an.',
  next: 'NÄCHSTE',
  notFoundPage: {
    invitation: {
      header: 'The invitation has expired',
      content:
        'If you are not able to find the problem, please contact your application manager',
    },
    loginMigration: {
      header: 'We have moved the login page!',
      content:
        'The login flow is now specific for your organisation.<br>Please contact your application manager(s) of your organisation for support.',
    },
    header: 'This is not the correct URL',
    content:
      'Please check the URL.<br>If you are not able to find the problem, please contact your application manager.',
  },
  loadingPage: {
    loading: 'Loading',
  },
  password: password.de,
  passwordRecovery: {
    forcePasswordChange:
      'Die Organisation wurde so eingestellt, dass das Passwort regelmäßig geändert wird muss sein. Sie müssen jetzt das Passwort ändern',
    passwordRecoveryDoNotExists:
      'Link zur Passwortwiederherstellung wurde nicht gefunden.',
    resetYourPassword: 'Setze dein Passwort zurück',
    requestPasswordChangeBottomText: 'Geben Sie Ihr neues Passwort ein',
    requestPasswordChangeButtonText: 'Passwort zurücksetzen',
    requestWasSentUpperText: 'Anfrage wurde gesendet',
    requestWasSentBottomText: 'Folgen Sie den Anweisungen in der E-Mail',
    resetPasswordRequestBottomText: 'Sie erhalten eine E-Mail mit Anweisungen',
  },
  passwordRequired: passwordRequired.de,
  policy: 'Datenschutz-Bestimmungen',
  registration: {
    idp: {
      activation: {
        title: 'Kontoaktivierung',
        inProgress: 'Ihre Kontoaktivierung ist in Bearbeitung.',
        emailInfo:
          'Sie erhalten in Kürze eine zusätzliche E-Mail mit weiteren Anweisungen zur Einrichtung Ihres Kontos.',
        emailSpamInfo:
          'Falls Sie die E-Mail nicht innerhalb weniger Minuten erhalten, überprüfen Sie bitte Ihren Spam-Ordner oder wenden Sie sich an Ihren Anwendungsadministrator.',
      },
    },
    button: 'Registrieren',
    error: {
      codeExpiredOrNotFound: 'Einladung ist abgelaufen',
      emailNotUnique: 'E-Mail-Adresse wird verwendet',
      invalid: {
        code: 'Falscher Bestätigungscode',
      },
      incorrectPhoneNumber: 'Telefonnummer ist falsch.',
      passwordsDontMatch: 'Passwörter stimmen nicht überein',
      passwordRequired: 'Passwort ist erforderlich',
      passwordValidationError: {
        uppercaseRequired:
          'Schwach - mindestens ein Großbuchstabe ist erforderlich',
        lowercaseRequired:
          'Schwach - mindestens ein Kleinbuchstabe ist erforderlich',
        numberRequired: 'Schwach - mindestens eine Zahl ist erforderlich',
        minRequired: 'Schwach - Passwort ist zu kurz',
      },
      phoneNumberInvalidFormat: 'Ungültiges Telefonnummernformat',
      phoneNumberRequired: 'Telefonnummer ist erforderlich',
      confirmPasswordRequired: 'Passwortbestätigung ist erforderlich',
      unexpected:
        'Unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut',
    },
    password: {
      strong: 'Stark',
      good: 'Gut',
      fair: 'Gut genug',
    },
    info: 'Erstellen Sie ein Konto für',
    label: {
      phoneNumber: 'Handy Nummer',
      password: 'Passwort erstellen',
      confirmPassword: 'Passwort bestätigen',
    },
    placeholder: {
      phoneNumber: 'Trage deine Telefonnummer ein',
      password: 'Geben Sie Ihr Passwort ein',
      confirmPassword: 'Geben Sie Ihr Passwort erneut ein',
    },
  },
  twoFactorAuthenticationQrCode: {
    setupAuthentication:
      'Der Anwendungsmanager hat die Zwei-Faktor-Authentifizierung für die Anmeldung erforderlich gemacht',
    scanQR:
      'Scannen Sie den QR-Code mit dem Authentifikator, oder geben Sie den obigen code manuell ein',
  },
  sendNew: 'NEUEN CODE SENDEN',
  sendNewCodeTimerInfo: 'Neuen Code senden in: ',
  smsAttemptsExceeded:
    'Code nicht erhalten? Kontaktieren Sie Ihre Organisation',
  sso: {
    or: 'ODER',
    placeholder: 'Option auswählen',
    login: 'Einloggen mit SSO',
  },
  success: {
    passwordChange: 'Passwort wurde erfolgreich geändert.',
    registrationCompleted: 'Registrierung erfolgreich abgeschlossen.',
  },
  terms: 'Nutzungsbedingungen',
  title: logInVerb.de + ' bei myneva',
  typeYourEmail: 'Geben Sie Ihre E-Mail-Adresse ein',
  typeYourPassword: 'Geben Sie Ihr Passwort ein',
  usernameRequired: usernameRequired.de,
  username: username.de,
  surveyRequestDialog: {
    infoContent:
      'Wenn Sie an der Umfrage teilnehmen, werden Sie auf eine neue Seite weitergeleitet.',
    doNotPresent: 'Nicht mehr darauf hinweisen',
    cancel: 'Nicht jetzt',
    submit: 'Umfrage durchführen',
    popupsBlockedAlert:
      'Ihr Browser blockiert das Öffnen eines zusätzlichen Tabs. Bitte vertrauen Sie {{ url }} in den Einstellungen Ihres Browsers.',
  },
};
