<regas-layout>
  <div class="account-activation-info">
    <h3>
      {{ 'registration.idp.activation.title' | translate: translations }}
    </h3>
    <p>
      {{ 'registration.idp.activation.inProgress' | translate: translations }}
    </p>
    <p>
      {{ 'registration.idp.activation.emailInfo' | translate: translations }}
    </p>
    <p>
      {{
        'registration.idp.activation.emailSpamInfo' | translate: translations
      }}
    </p>
  </div>
</regas-layout>
