import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private language: string;

  setLanguage(language: string): void {
    this.language = language;
  }

  getLanguage(): string {
    return this.language?.substr(0, 2) ?? '';
  }

  initLanguage(): void {
    if (!this.language) {
      this.setLanguage(
        navigator.cookieEnabled &&
          localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE) !== null
          ? (localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE) as string)
          : navigator.language.split('-')[0],
      );
    }
  }
}
